<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">Artigos</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item"><a href="#">Home</a></li>
                     <li class="breadcrumb-item active">Dashboard</li>
                     <li class="breadcrumb-item active">Artigos</li>
                     <li class="breadcrumb-item active">Criar Artigo</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="row">
            <div class="col-md-12">
               <div class="card card-outline card-info">
                  <div class="card-header">
                     <h3 class="card-title">
                        Cadastrar Artigo
                     </h3>
                  </div>
                  <!-- /.card-header -->

                  <div class="card-body">

                     <div class="form-group mb-4">
                        <label for="exampleInputEmail1">Título</label>
                        <input type="text" class="form-control" placeholder="Título">
                     </div>

                     <QuillVueEditor />

                     <div class="row mt-4">
                        <div class="col-5">
                           <div class="form-group">
                              <label>Autor</label>
                              <select class="form-control">
                                 <option>Antônio Claudio</option>
                                 <option>Wesley Almeida</option>
                                 <option>Lucas Mendes</option>
                              </select>
                           </div>
                        </div>

                        <div class="col-4">
                           <div class="form-group">
                              <label>Categoria</label>
                              <select class="form-control">
                                 <option>Humor e Saúde</option>
                                 <option>Alimentação Saudável</option>
                                 <option>Dicas de Saúde</option>
                                 <option>Diabétes</option>
                                 <option>Estética e Beleza</option>
                              </select>
                           </div>
                        </div>

                        <div class="col-3 mt-3">
                           <div class="form-group">
                              <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                 <input type="checkbox" class="custom-control-input" id="customSwitch3">
                                 <label class="custom-control-label" for="customSwitch3">Comentários</label>
                              </div>
                           </div>
                        </div>

                     </div>
                     <!-- ./row -->

                  </div>
                  <div class="card-footer">
                     <div class="row justify-content-end">
                        <button class="btn btn-success btn-sm text-bold">Salvar</button>
                     </div>
                  </div>
               </div>
            </div>
            <!-- /.col-->
         </div>
         <!-- ./row -->
      </section>
   </div>
</template>

<script>
import QuillVueEditor from "../component/QuillVueEditor.vue";

export default {
   name: "ArticlesForm",
   components: { QuillVueEditor }
}
</script>

<style>
.custom-file-label {
   background-color: #3498db;
   color: #fff;
   padding: 10px 20px;
   border: none;
   cursor: pointer;
}

.custom-file-label:hover {
   background-color: #2580b3;
}

.custom-file-label:active {
   background-color: #176798;
}
</style>
